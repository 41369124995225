<template>
  <div>
    Trwa uwierzetylnianie.
  </div>
</template>

<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class IndexPage extends Vue {
  async created() {
    localStorage.removeItem("wallet-frontend-token");
    if (this.$route.params["token"] && this.$route.params["token"] !== "") {
      localStorage.setItem("wallet-frontend-token", this.$route.params["token"]);
      await this.$router.push("/recommendations/list");
    } else {
      window.location.href = process.env.VUE_APP_PRODUCTION_URL;
    }
  }
}
</script>

<style scoped></style>
